import { Channel, ChannelData, Log, Order } from "../../../types";
import { get, patch, post, remove } from "../axiosConfig";

export const ChannelAPI = {
  async getChannelsList(): Promise<Channel[]> {
    const { data } = await get("/api/v1/channels");
    return data;
  },
  async addChannel(channel_link: string): Promise<true> {
    const { data } = await post("/api/v1/channels", { channel_link });
    return data;
  },
  async deleteChannel(id: number): Promise<true> {
    const { data } = await remove(`/api/v1/channels/${id}`);
    return data;
  },
  async getChannel(id: string): Promise<ChannelData> {
    const { data } = await get(`/api/v1/channels/${id}`);
    return data;
  },
  async getOrders(): Promise<Order[]> {
    const { data } = await get("/api/v1/orders");
    return data;
  },
  async updateOrders({
    payload,
    id,
  }: {
    id: string;
    payload: {
      value: number | null;
      type: string;
      orders: number[];
      date: string;
      interval: number;
      anti_minus: number | null | string;
    }[];
  }): Promise<Order[]> {
    const { data } = await patch(`/api/v1/channels/${id}/orders`, [...payload]);
    return data;
  },
  async updateComment({
    comment,
    id,
  }: {
    comment: string;
    id: string;
  }): Promise<true> {
    const { data } = await patch(`/api/v1/channels/${id}/comment`, comment);
    return data;
  },
  async getChannelLogs({
    id,
    toDate,
    fromDate,
  }: {
    id: string;
    toDate?: Date;
    fromDate?: Date;
  }): Promise<{ logs: Log[]; total: number }> {
    const params = { min_date: fromDate, max_date: toDate };
    const { data } = await get(`/api/v1/channels/${id}/logs`, { params });
    return data;
  },
};
