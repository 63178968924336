export const getTime = (date?: Date) => {
  if (!date) {
    return;
  }
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}Z`;

  return formattedTime;
};
