export const getDateObjectFromTimeString = (timeString?: string) => {
  if (!timeString) {
    return;
  }
  const [hour, minute, secondMillis] = timeString.split(/[:.Z]/).map(Number);
  const currentDate = new Date();
  currentDate.setHours(hour);
  currentDate.setMinutes(minute);
  currentDate.setSeconds(secondMillis / 1000);
  currentDate.setMilliseconds(secondMillis % 1000);

  return currentDate;
};
