import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { RepostOrderPayload } from "../../../types";
import NumberFormat from "react-number-format";
import { getValue, needMultiply } from "../../../utils/inputTransform";
import { getRepostOrderSchema } from "../../../utils/schemas/RepostOrderSchemas";
import { isValueBetween } from "../../../utils/isValueBetween";
import { RepostAPI } from "../../../utils/api/requests/repost-requests";
import { Select } from "../../atoms/Select";
import { TimePicker } from "../../atoms/TimePicker";
import { getTime } from "../../../utils/getTime";

interface AddRepostOrderModalProps {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
  accountData: { value: string; label: string }[];
}

export const AddRepostOrderModal: FC<AddRepostOrderModalProps> = ({
  open,
  handleClose,
  refetch,
  accountData,
}) => {
  const form = useForm<RepostOrderPayload>({
    mode: "onChange",
    resolver: joiResolver(getRepostOrderSchema()),
  });
  const { handleSubmit, formState, control, reset } = form;

  const { isDirty, isValid } = formState;

  const { mutate, isLoading } = useMutation({
    mutationFn: RepostAPI.addRepostOrder,
    onSuccess: () => {
      refetch();
      toast.success("Заказ на репост успешно добавлен");
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      const errorText = err?.response?.data?.detail;
      if (typeof errorText === "string") {
        toast.error(err?.response?.data?.detail);
      } else {
        toast.error("Ошибка");
      }
    },
  });

  const onSubmit = async (formData: RepostOrderPayload) => {
    const data = {
      ...formData,
      time_from: formData?.time_from && getTime(new Date(formData?.time_from)),
      time_to: formData?.time_to && getTime(new Date(formData?.time_to)),
      delay: formData.delay || undefined,
      random: formData.random || undefined,
    };
    mutate(data);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...form}>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">
            Добавить заказ на репосты
          </DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", width: "350px" }}
          >
            <Controller
              control={control}
              name="channel_source"
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={"Канал"}
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    marginTop: "12px",
                  }}
                  error={!!error}
                />
              )}
            />
            <Controller
              control={control}
              name="channel_target"
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={"Репост канал"}
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    marginTop: "12px",
                    mb: "12px",
                  }}
                  error={!!error}
                />
              )}
            />
            <Select
              data={accountData}
              name={"account_id"}
              isEditing={true}
              hideLabel={true}
              fullWidth={true}
              placeholder={"Id аккаунта"}
            />
            <Controller
              control={control}
              //   defaultValue={""}
              name={`delay`}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState: { error },
              }) => (
                <NumberFormat
                  customInput={TextField}
                  inputRef={ref}
                  onValueChange={needMultiply(1, onChange)}
                  value={getValue(value, true, 1)}
                  type="text"
                  variant="outlined"
                  label="Задержка, мин."
                  error={!!error}
                  fullWidth={true}
                  decimalScale={0}
                  allowNegative={false}
                  autoComplete="off"
                  sx={{
                    marginTop: "12px",
                  }}
                />
              )}
            />
            <Controller
              control={control}
              //   defaultValue={""}
              name={`random`}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState: { error },
              }) => (
                <NumberFormat
                  customInput={TextField}
                  inputRef={ref}
                  onValueChange={needMultiply(1, onChange)}
                  value={getValue(value, true, 1)}
                  type="text"
                  variant="outlined"
                  label="Рандом, %"
                  error={!!error}
                  fullWidth={true}
                  decimalScale={0}
                  allowNegative={false}
                  isAllowed={({ floatValue }) =>
                    floatValue === undefined ||
                    isValueBetween(floatValue, 0, 100)
                  }
                  sx={{
                    marginTop: "12px",
                  }}
                  autoComplete="off"
                />
              )}
            />
            <TimePicker name={"time_from"} placeholder={"Время начала"} />
            <TimePicker name={"time_to"} placeholder={"Время окончания"} />
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px" }}>
            <Button variant="outlined" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid || isLoading}
            >
              Добавить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
