import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  SxProps,
  TextField,
} from "@mui/material";
import { FC } from "react";
import { useController } from "react-hook-form";

interface SelectProps {
  data?: { value: any; label: string }[];
  name: string;
  isEditing: boolean;
  hideLabel?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  sx?: SxProps;
}

export const Select: FC<SelectProps> = ({
  data,
  name,
  isEditing,
  hideLabel = false,
  fullWidth = false,
  placeholder,
  sx,
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
  });

  const label = data?.find((item) => item.value === value)?.label;

  return (
    <Box sx={sx}>
      {!hideLabel && <InputLabel>Тип услуги</InputLabel>}
      {isEditing ? (
        <FormControl fullWidth>
          <TextField
            select
            id="demo-simple-select"
            sx={{ width: fullWidth ? "100%" : "160px" }}
            onChange={onChange}
            value={value}
            defaultValue={value?.toString()}
            error={!!error}
            label={placeholder}
          >
            {data &&
              data.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      ) : (
        <Box
          sx={{
            width: "160px",
            height: "55px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Box>
      )}
    </Box>
  );
};
