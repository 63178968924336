import { RepostOrder, RepostOrderPayload } from "../../../types";
import { get, patch, post, remove } from "../axiosConfig";

export const RepostAPI = {
  async getRepostOrderList(): Promise<RepostOrder[]> {
    const { data } = await get("/api/v1/repost_orders");
    return data;
  },

  async addRepostOrder(formData: RepostOrderPayload): Promise<true> {
    const { data } = await post("/api/v1/repost_orders", formData);
    return data;
  },

  async removeRepostOrder(id: number): Promise<true> {
    const { data } = await remove(`/api/v1/repost_orders/${id}`);
    return data;
  },

  async updateRepostOrder({
    id,
    formData,
  }: {
    id: number;
    formData: RepostOrderPayload;
  }): Promise<true> {
    const { data } = await patch(`/api/v1/repost_orders/${id}`, formData);
    return data;
  },

  async setEnabled({ id, enabled }: { id: number; enabled: boolean }) {
    const { data } = await patch(
      `/api/v1/repost_orders/${id}/enabled`,
      enabled
    );
    return data;
  },

  async setPosition({ id, position }: { id: string; position: number }) {
    const { data } = await patch(
      `/api/v1/repost_orders/${id}/position`,
      position
    );
    return data;
  },
};
