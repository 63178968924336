import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Log } from "../../../types";
import { getDate } from "../../../utils/getDate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getFormattedNumber } from "../../../utils/GetFormattedNumber";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface LogItemProps {
  log: Log;
}

export const LogItem: FC<LogItemProps> = ({ log }) => {
  const error = JSON.stringify(log?.error);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 16,
    },
  }));

  return (
    <>
      <Box
        sx={{
          height: "40px",
          borderBottom: "1px solid #e0e0e0",
          display: "grid",
          gridTemplateColumns: "0.7fr 0.7fr 1fr 0.7fr 0.3fr",
          px: "8px",
          ml: "16px",
          paddingBottom: "10px",
          "&:hover": {
            background: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarMonthIcon style={{ marginRight: "4px" }} />
          {getDate(log.created_at)}
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Тип услуги: "}
          <Typography sx={{ ml: "4px" }}>{log.order_type}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Услуга: "}
          <Typography sx={{ ml: "4px" }}>{log.order}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Количество: "}
          <Typography sx={{ ml: "4px" }}>
            {getFormattedNumber(log.value)}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {log.success ? (
            <CheckCircleIcon color="success" />
          ) : (
            <LightTooltip title={error} arrow>
              <CancelIcon color="error" fontSize="medium" />
            </LightTooltip>
          )}
        </Box>
      </Box>
    </>
  );
};
