import { Box, CircularProgress, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useMutation, useQuery } from "react-query";
import { AccountAPI } from "../utils/api/requests/account-requests";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { RepostOrder } from "../types";
import { AddRepostOrderModal } from "../ui/organisms/AddRepostOrderModal";
import { RepostAPI } from "../utils/api/requests/repost-requests";
import { RepostOrderItem } from "../ui/molecules/RepostOrderItem";
import { AxiosError } from "../utils/api/axiosErrorType";
import { toast } from "react-toastify";
import { getAccountData } from "../utils/getAccountData";

export const Reposts = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [data, setData] = useState<RepostOrder[] | null>(null);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const arr = result.map((item, index) => ({
      ...item,
      sortNumber: index,
    }));

    return arr;
  };

  const { data: accounts } = useQuery({
    queryFn: AccountAPI.getAccountsList,
    queryKey: "accounts",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: fetchData, refetch } = useQuery({
    queryFn: RepostAPI.getRepostOrderList,
    queryKey: "reposts",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (fetchData) {
      setData(fetchData);
    }
  }, [fetchData]);

  const { mutate } = useMutation({
    mutationFn: RepostAPI.setPosition,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      const err = error as AxiosError;
      const errorText = err?.response?.data?.detail;
      if (typeof errorText === "string") {
        toast.error(err?.response?.data?.detail);
      } else {
        toast.error("Ошибка");
      }
      refetch();
    },
  });

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    const itemId = result.draggableId;
    if (data?.length) {
      const newData = reorder(data, sourceIndex, destIndex);
      setData(newData);
      mutate({ id: itemId, position: destIndex });
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <Typography variant="h4">Репосты</Typography>
        <AddCircleIcon
          fontSize="medium"
          style={{ transition: "all 0.2s linear" }}
          sx={{
            ml: "6px",
            mt: "4px",
            cursor: "pointer",
            "&:hover": { color: "#1976d2" },
          }}
          color="disabled"
          onClick={() => {
            setModalOpened(true);
          }}
        />
      </Box>
      {!data && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data &&
                  data.length !== 0 &&
                  data.map((repostOrder, index) => (
                    <Draggable
                      key={repostOrder.id}
                      draggableId={repostOrder.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RepostOrderItem
                            repostOrder={repostOrder}
                            key={repostOrder.id}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
      {data && data.length === 0 && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <div>Заказов на репосты нет</div>
        </Box>
      )}
      {modalOpened && (
        <AddRepostOrderModal
          open={modalOpened}
          handleClose={() => {
            setModalOpened(false);
          }}
          refetch={refetch}
          accountData={getAccountData(accounts)}
        />
      )}
    </>
  );
};
