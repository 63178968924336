import React, { useEffect, useState } from "react";
import { ChannelAPI } from "../utils/api/requests/channel-requests";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { LogItem } from "../ui/molecules/LogItem";
import { getFormattedNumber } from "../utils/GetFormattedNumber";
import { QueryDatePicker } from "../ui/atoms/QueryDatePicker";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { queryClient } from "../..";

export default function Logs() {
  const { id } = useParams<Record<"id", string>>();
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);

  const { data, refetch } = useQuery({
    queryFn: () =>
      ChannelAPI.getChannelLogs({
        id: id!,
        fromDate: fromDate,
        toDate: toDate,
      }),
    queryKey: "logs",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [toDate, fromDate, refetch]);

  useEffect(() => {
    return () => {
      queryClient.resetQueries("logs");
      queryClient.resetQueries("channelData");
    };
  }, []);

  const { data: channel } = useQuery({
    queryFn: () => ChannelAPI.getChannel(id!),
    onError: () => {
      navigate("/channels");
    },
    queryKey: "channelData",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {channel && data && (
        <Box
          sx={{
            marginBottom: "12px",
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              fontSize="medium"
              style={{ transition: "all 0.2s linear" }}
              sx={{
                ml: "6px",
                mt: "4px",
                cursor: "pointer",
                "&:hover": { color: "#1976d2" },
              }}
              color="disabled"
              onClick={() => {
                navigate(`/channels/${id}`);
              }}
            />
            <Typography variant="h5">{`Логи ${
              channel?.channel.name
            } (Всего: ${getFormattedNumber(data.total)})`}</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <QueryDatePicker
              value={fromDate}
              setValue={setFromDate}
              placeholder="Начало периода"
            />
            <Typography sx={{ ml: "6px", mr: "6px" }}> —</Typography>
            <QueryDatePicker
              value={toDate}
              setValue={setToDate}
              placeholder="Конец периода"
            />
          </Box>
        </Box>
      )}

      {!channel && !data && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {data &&
        channel &&
        data.logs.length !== 0 &&
        data.logs.map((log, idx) => {
          return <LogItem log={log} key={idx} />;
        })}
      {data && data.logs.length === 0 && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <div>Логов нет</div>
        </Box>
      )}
    </>
  );
}
