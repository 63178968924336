import { FC, useState } from "react";
import { Box, Switch, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { RepostOrder } from "../../../types";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { DeleteRepostOrderModal } from "../../organisms/DeleteRepostOrderModal";
import { useMutation, useQuery } from "react-query";
import { RepostAPI } from "../../../utils/api/requests/repost-requests";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { UpdateRepostOrderModal } from "../../organisms/UpdateRepostOrderModal";
import { AccountAPI } from "../../../utils/api/requests/account-requests";
import { getAccountData } from "../../../utils/getAccountData";
import { getColor } from "../../../utils/GetStatusColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";

interface RepostOrderItemProps {
  repostOrder: RepostOrder;
}

export const RepostOrderItem: FC<RepostOrderItemProps> = ({ repostOrder }) => {
  const [modal, setModal] = useState<"deleteModal" | "updateModal" | null>(
    null
  );

  const handleCloseModal = () => {
    setModal(null);
  };

  const { data: accounts } = useQuery({
    queryFn: AccountAPI.getAccountsList,
    queryKey: "accounts",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { refetch } = useQuery({
    queryFn: RepostAPI.getRepostOrderList,
    queryKey: "reposts",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate } = useMutation({
    mutationFn: RepostAPI.setEnabled,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      const err = error as AxiosError;
      const errorText = err?.response?.data?.detail;
      if (typeof errorText === "string") {
        toast.error(err?.response?.data?.detail);
      } else {
        toast.error("Ошибка");
      }
    },
  });

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.dark("Скопировано: " + text);
  };

  const handleSetEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    mutate({ id: repostOrder.id, enabled: event.target.checked });
  };

  return (
    <>
      <Box
        sx={{
          height: "40px",
          borderBottom: "1px solid #e0e0e0",
          display: "grid",
          gridTemplateColumns:
            "0.1fr 0.7fr 0.1fr 0.7fr 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 0.1fr 0.1fr",
          px: "8px",
          paddingBottom: "10px",
          background: "#fff",
          "&:hover": {
            background: "#f5f5f5",
          },
          "&:active": {
            background: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <Switch
            defaultChecked={repostOrder.enabled}
            onChange={handleSetEnabled}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
          />
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => copyText(repostOrder.channel_source)}
        >
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {repostOrder.channel_source}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <ArrowForwardIcon />
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => copyText(repostOrder.channel_target)}
        >
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {repostOrder.channel_target}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => copyText(repostOrder.account_id)}
        >
          <PersonIcon />
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {repostOrder.account_id}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <CalendarMonthIcon />
          <Typography sx={{ ml: "4px" }}>
            {repostOrder.time_from
              ? repostOrder.time_from?.substring(0, 5) +
                "-" +
                repostOrder.time_to?.substring(0, 5)
              : "-"}
          </Typography>
        </Box>

        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <AccessTimeIcon />
          <Typography sx={{ ml: "4px" }}>
            {repostOrder.delay ? repostOrder.delay + " мин." : "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <ShuffleIcon />
          <Typography sx={{ ml: "4px" }}>
            {repostOrder.random ? repostOrder.random + "%" : "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <Typography
            sx={{ ml: "4px" }}
            color={getColor(repostOrder.status.name)}
          >
            {repostOrder.status.title}
          </Typography>
        </Box>

        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={() => setModal("updateModal")}
        >
          <EditIcon sx={{ mr: "4px" }} />
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={() => setModal("deleteModal")}
        >
          <DeleteOutlineIcon sx={{ mr: "4px" }} />
        </Box>
      </Box>
      <DeleteRepostOrderModal
        open={modal === "deleteModal"}
        handleClose={handleCloseModal}
        id={repostOrder.id}
      />
      {modal === "updateModal" && accounts && (
        <UpdateRepostOrderModal
          open={modal === "updateModal"}
          handleClose={handleCloseModal}
          refetch={refetch}
          accountData={getAccountData(accounts)}
          repostOrder={repostOrder}
        />
      )}
    </>
  );
};
