import { Account } from "../types";

export const getAccountData = (accounts?: Account[]) => {
  if (!accounts) {
    return [];
  }
  const accountData: { label: string; value: string }[] = [];
  if (accounts && accounts?.length > 0) {
    accounts?.forEach((account) => {
      if (account.status.name === "active") {
        accountData.push({ label: account.id, value: account.id });
      }
    });
  }
  return accountData;
};
