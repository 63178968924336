import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Account } from "../../../types";
import { DeleteAccountModal } from "../../organisms/DeleteAccountModal";
import { getColor } from "../../../utils/GetStatusColor";
import EditIcon from "@mui/icons-material/Edit";
import { UpdateProxyModal } from "../../organisms/UpdateProxyModal";

interface AccountItemProps {
  account: Account;
}

export const AccountItem: FC<AccountItemProps> = ({ account }) => {
  const [modal, setModal] = useState<"deleteModal" | "updateProxyModal" | null>(
    null
  );

  const handleCloseModal = () => {
    setModal(null);
  };

  return (
    <>
      <Box
        sx={{
          height: "40px",
          borderBottom: "1px solid #e0e0e0",
          display: "grid",
          gridTemplateColumns: "1fr 0.6fr 0.8fr 0.8fr 0.2fr",
          px: "8px",
          paddingBottom: "10px",
          background: "#fff",
          "&:hover": {
            background: "#f5f5f5",
          },
          "&:active": {
            background: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            mr: "8px",
          }}
        >
          <InsertLinkIcon style={{ marginRight: "4px" }} />
          <Typography
            sx={{
              width: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {account.proxy || "-"}
          </Typography>
          <EditIcon
            fontSize="small"
            style={{
              marginLeft: "4px",
              color: "inherit",
              cursor: "pointer",
            }}
            onClick={() => setModal("updateProxyModal")}
          />
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
          }}
        >
          id:
          {account.id}
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          name:
          <Typography sx={{ ml: "4px" }}>{account.name}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Статус: "}{" "}
          <Typography sx={{ ml: "4px" }} color={getColor(account.status.name)}>
            {account.status.title}
          </Typography>
        </Box>

        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={() => setModal("deleteModal")}
        >
          <DeleteOutlineIcon sx={{ mr: "4px" }} />
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            Удалить
          </Typography>
        </Box>
      </Box>
      <DeleteAccountModal
        open={modal === "deleteModal"}
        handleClose={handleCloseModal}
        id={account.id}
      />
      <UpdateProxyModal
        open={modal === "updateProxyModal"}
        handleClose={handleCloseModal}
        id={account.id}
        proxy={account.proxy}
      />
    </>
  );
};
