import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Channel } from "../../../types";
import { DeleteChannelModal } from "../../organisms/DeleteChannelModal";
import { getColor } from "../../../utils/GetStatusColor";
import { useNavigate } from "react-router-dom";
import { getFormattedNumber } from "../../../utils/GetFormattedNumber";

interface ChannelItemProps {
  channel: Channel;
}

export const ChannelItem: FC<ChannelItemProps> = ({ channel }) => {
  const [modal, setModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setModal(false);
  };

  const onRowClick = () => {
    navigate(`/channels/${channel.id}`);
  };

  return (
    <>
      <Box
        onClick={onRowClick}
        sx={{
          height: "40px",
          borderBottom: "1px solid #e0e0e0",
          display: "grid",
          gridTemplateColumns: "0.5fr 1fr 0.6fr 0.6fr 1fr 0.2fr",
          px: "8px",
          cursor: "pointer",
          paddingBottom: "10px",
          "&:hover": {
            background: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
          }}
        >
          <InsertLinkIcon style={{ marginRight: "4px" }} />
          {channel.name}
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Username: "}
          <Typography sx={{ ml: "4px" }}>{channel.username}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Статус: "}{" "}
          <Typography sx={{ ml: "4px" }} color={getColor(channel.status.name)}>
            {channel.status.title}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Тип: "}{" "}
          <Typography sx={{ ml: "4px" }}>
            {channel.channel_type.title}
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Кол-во подписчиков: "}{" "}
          <Typography sx={{ ml: "4px" }}>
            {getFormattedNumber(channel.subscribers)}
          </Typography>
        </Box>

        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setModal(true);
          }}
        >
          <DeleteOutlineIcon sx={{ mr: "4px" }} />
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            Удалить
          </Typography>
        </Box>
      </Box>

      <DeleteChannelModal
        open={modal}
        handleClose={handleCloseModal}
        id={channel.id}
      />
    </>
  );
};
