import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { useController } from "react-hook-form";
import { FC } from "react";

interface TimePickerProps {
  name: string;
  placeholder: string;
}

export const TimePicker: FC<TimePickerProps> = ({ name, placeholder }) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
  });
  return (
    <Box sx={{ marginTop: "12px" }} className="repost-order-date-picker">
      <DatePicker
        locale={ru}
        showTimeSelect
        selected={value}
        onChange={onChange}
        timeCaption={"Время"}
        placeholderText={placeholder}
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="p"
        dateFormat="p"
        showPopperArrow={false}
        isClearable
      />
    </Box>
  );
};
