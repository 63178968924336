import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import React, { FC } from "react";
import { useController } from "react-hook-form";

interface MultiSelectProps {
  data?: { value: any; label: string }[];
  name: string;
  isEditing: boolean;
}

export const MultiSelect: FC<MultiSelectProps> = ({
  data,
  name,
  isEditing,
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
  });
  const labels: string[] = [];

  data?.forEach((item) => {
    if (value.includes(item.value)) {
      labels.push(item.label);
    }
  });

  return (
    <Box sx={{ ml: "10px" }}>
      <InputLabel>Услуга</InputLabel>
      {isEditing ? (
        <Select
          multiple={!!value}
          defaultValue={value}
          value={value}
          //@ts-ignore
          onChange={onChange}
          sx={{ width: "400px" }}
          error={!!error}
        >
          {data &&
            data.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <Box
          sx={{
            width: "400px",
            height: "55px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {labels.join()}
        </Box>
      )}
    </Box>
  );
};
