import Joi from "joi";
import { RepostOrderPayload } from "../../types";

export const getRepostOrderSchema = () =>
  Joi.object<RepostOrderPayload>({
    channel_source: Joi.string().required(),
    channel_target: Joi.string().required(),
    account_id: Joi.string().required(),
  }).unknown(true);

export const getUpdateRepostOrderSchema = () =>
  Joi.object<RepostOrderPayload>({
    account_id: Joi.string().required(),
  }).unknown(true);
